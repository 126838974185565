import React from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function NotFound(props) {
  
  return (
    <>
    <Navbar />
    <div id="webSiteContent" className="card-deck">
      <div className="container"> 
        <div className="d-flex align-items-start">

        <section className="features">
                <h1>
                  Cette page est introuvable
                </h1>
        </section>
       </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default NotFound;

