import React from 'react';
import { AiFillYoutube } from 'react-icons/ai';

function Footer() {

  return (

    <footer className="bg-light  text-lg-start">

      <div className="container p-4">

        <div className="row">

          <div className="col-md-4 col-12 mb-4 mb-md-0 font-weight-bold">
            <h5 className="text-uppercase">Coolespace description</h5>

            <p>
              Analyse, Conception, Développement de Solutions informatiques ... <br /> Coolespace.com
            </p>
          </div>

          <div className="col-md-3 col-12 mb-4 mb-md-0">
            <h5 className="text-uppercase">Application</h5>

            <ul className="list-unstyled mb-0">
              <li>
                <a href="/" className="text-dark">IntelliNote</a>
              </li>
              <li>
                <a href="/" className="text-dark">O***</a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 col-12 mb-4 mb-md-0">
            <h5 className="text-uppercase">Game</h5>

            <ul className="list-unstyled mb-0">
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.coolespace.brainspace" className="text-dark">BrainSpace</a>
              </li>
              <li>
                <a href="https://sentensplit.coolespace.com/" className="text-dark">SentenSplit</a>
              </li>
            </ul>
          </div>


          <div className="col-md-2 col-12 mb-4 mb-md-0">
            <h5 className="text-uppercase">contact</h5>
            <div className="pt-2">
              <a className="text-decoration-none" href="mailto:?to=loris.tchounang@gmail.com">
                coolespace.com@gmail.com
              </a>
            </div>

            <div className="pt-2">
              <a className="text-decoration-none" href="https://www.youtube.com/channel/UCaJBG-kc3ILvABfVAy0GnvA">
                coolespace <AiFillYoutube size={25} />
              </a>
            </div>
          </div>

        </div>

        <div className="text-center p-3 ">
          © 2021 Copyright:
          <a className="text-dark" href="/">coolespace.com</a>
          <div className="col-12 text-right">

          </div>

        </div>

      </div>

    </footer>

  );
}

export default Footer;

