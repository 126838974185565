import React from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import Tilt from 'react-vanilla-tilt'

function Game() {

    return (
        <>
            <Navbar />
            <div className="text-center border shado">
                <h1>
                    JEUX
                </h1>
            </div>
            <div id="webSiteContent" className="card-deck">
                <div className="container ">
                    <div className="d-flex justify-content-center ">

                        <Tilt className="p-0 game-item text-center col-4" >

                            <div className="">
                                <a className="text-decoration-none text-light" href="https://play.google.com/store/apps/details?id=com.coolespace.brainspace">
                                    <img className="col-12" src="img/brainspace.png" alt="brainspacegame application" />
                                    <h2>Brainspace</h2>
                                    <p>Reflex game where the goal is to go as far as possible with your ship to get the highest score.</p>
                                </a>

                            </div>
                        </Tilt>

                        <Tilt className="p-0 game-item text-center  col-4" >

                            <div className="">
                                <a className="text-decoration-none text-light" href="https://sentensplit.coolespace.com/">
                                    <img className="col-12" src="img/sentensplit.PNG" alt="Sentensplit website" />
                                    <h2>Sentensplit</h2>
                                    <p>[Fr] : Jeux de réflexion où le but est de trouver le mot qui se cache dans la phrase.</p>
                                </a>

                            </div>
                        </Tilt>

                        
                        <Tilt className="p-0 game-item text-center col-4">

                            <div className="">
                                <a className="text-decoration-none text-light" href="https://play.google.com/store/apps/details?id=com.coolespace.conf&gl=FR">
                                    <img className="col-12 h-50" src="img/conf.png" alt="conf application" />
                                    <h2>Conf</h2>
                                    <p>Puzzle Game <br/> The goal is to have an all-blue population. The people of the red population must not touch the people of the blue population.</p>
                                </a>

                            </div>
                        </Tilt>
{/* 
                        <Tilt className="p-0 game-item text-center col-4">

                            <div className="">
                                <a className="text-decoration-none text-light" href="https://play.google.com/store/apps/details?id=com.coolespace.littlering&gl=FR">
                                    <img className="col-12" src="img/littlering.png" alt="littlering application" />
                                    <h2>LittleRing</h2>
                                    <p>Reflex Game <br/> Touch the most blue rings</p>
                                </a>

                            </div>
                        </Tilt> */}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Game;

