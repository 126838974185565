import React from 'react';

import Tilt from 'react-vanilla-tilt'

const style = {
    backgroundColor: "rgba(89, 91, 97, 0.877)",
    borderTop: "1px solid rgb(255, 255, 255, 0.5)",
    borderLeft: "1px solid rgb(255, 255, 255, 0.5)",
    borderRadius: "0.5em"
}
export default function Formations() {

    return (
        <div className="container p-4"> 
                <Tilt  className="col-12 text-white text-center p-3 "
                 style={style}>
                        <div className="py-3">
                            <h2 className="display-5">Ingénieur en Systèmes d'Information</h2>
                        </div>
                        <p>
                            CNAM
                        </p>

                        <p>
                            2021 - 2024 | Alternance
                        </p>
                        <img className="little-picture"
                            src="https://www.adcf.org/images/AA-Visuel-AdCFdirect/934/Cnam.jpg"
                            alt="cnam"/>
                </Tilt>

                <Tilt  className="col-12 text-white text-center p-3 mt-4"
                 style={style}>
                        <div className="py-3">
                            <h2 className="display-5">Licence professionnelle en Informatique <br/> Web, Mobile et Business Intelligence (ACSID)</h2>
                        </div>
                        <p>
                            CNAM
                        </p>

                        <p>
                            2020 - 2021 | Alternance
                        </p>
                        <img className="little-picture"
                            src="https://www.adcf.org/images/AA-Visuel-AdCFdirect/934/Cnam.jpg"
                            alt="cnam"/>
                </Tilt>

                <Tilt  className="col-12 text-white text-center p-3 mt-4"
                style={style}>
                        <div className="py-3">
                            <h2 className="display-5">DUT Informatique</h2>
                        </div>
                        <p>
                            IUT DE MONTREUIL
                        </p>

                        <p>
                            2019 - 2020
                        </p>
                        <img className="little-picture"
                            src="https://www.iut.univ-paris8.fr/sites/default/files/LOGO%20IUT%20MONTREUIL%20Moyen.jpg"
                            alt="montreuil"/>
                </Tilt>

        </div>
  );
}
