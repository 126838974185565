import React from 'react';

import { competencesData } from './portfolio-Data';

import Tilt from 'react-vanilla-tilt'

export default function Competences() {
  
  //competencesData.map(cp => JSON.stringify(cp));

    return (
    <>
        {competencesData.map(cp => 
                <div key={"type"+cp.name}>
                    <div  className="textStyle text-center">
                    <h2 >{cp.name}</h2>
                    </div>
                    <div  className="d-md-flex  my-md-3 pl-md-2 ">
                    {cp.competences.map(c => 
                        <Tilt key={"competence"+c.name} className="shad  mr-md-4 w-100 px-4 pt-md-2 px-md-7 text-center text-light overflow-hidden"
                        style={{    backgroundColor: "rgba(89, 91, 97, 0.877)",
                            borderTop: "1px solid rgb(255, 255, 255, 0.5)",
                            borderLeft: "1px solid rgb(255, 255, 255, 0.5)",
                            borderRadius: "0.5em"
                            }}>
                        <div >
                            <div className="">
                                <h2 className="display-5">{c.name}</h2>
                            </div>

                            <div className="">

                            <img className="skill-picture"
                                src={c.link ? c.link : ""}
                                alt="competences"/>
                                </div>
                        </div>
                                </Tilt>
                    )}
                    </div>
                </div> 
            )}

        </>
  );
}
