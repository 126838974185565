import React from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import Tilt from 'react-vanilla-tilt'


function Application() {

    return (
        <>
            <Navbar />
            <div className="text-center border shado">
                <h1>
                    APPLICATIONS
                </h1>
            </div>
            <div id="webSiteContent" className="card-deck">
                <div className="container ">
                    <div className="d-flex justify-content-center flex-wrap ">


                        <Tilt className="p-0 game-item text-center ">

                            <div className=" ">
                                <a className="text-decoration-none text-light" href="/" aria-disabled>
                                    <img className="col-12" src="img/intelliNote.PNG" alt="Sentensplit website" />
                                    <h2>IntelliNote</h2>
                                    <p>Application multiplatforme de gestion de note</p>
                                </a>

                            </div>
                        </Tilt>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Application;

