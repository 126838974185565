import React from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Home(props) {
  props.history.push('/application')
  
  return (
    <>
    <Navbar />
    <div id="webSiteContent" className="card-deck">
      <div className="container"> 
        <div className="d-flex align-items-start">

        <section className="features">
                <h1>
                  ACCUEIL
                </h1>
        </section>
       </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default Home;

