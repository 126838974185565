import React, { useState } from 'react';

import Competences from './competences';
import Experiences from './experiences';
import Formations from './formations';

import { AiFillCloseCircle } from 'react-icons/ai';
import { HiMail } from 'react-icons/hi';
import { FaLinkedin } from 'react-icons/fa';

import Image from 'react-bootstrap/Image';

import './portfolio.css';

const pages = ["Expériences", "Compétences" ,"Formations"];

function Portfolio() {
  const [page, setPage ] = useState({left : pages[2] , center : pages[0] , right : pages[1]});
  const [showAbout, setShowAbout ] = useState(false);

  const changePage = (p) => {
    if(p === page.left){
      setPage({left : page.right , center : page.left , right : page.center})
    }
    else if(p === page.right){
      setPage({left : page.center , center : page.right , right : page.left})
    }
    else if (p === "about"){
      setShowAbout(true);
    }

  }

    return (
    <>
        <nav className=" site-header py-1">
      <div  className="container d-flex flex-column flex-md-row justify-content-between">
        <div className={ " itemMenu border rounded  p-1 " + ( page.center === "about" ? "border-bottom" : "" )}  onClick={() => changePage("about")}>A propos</div>
        <div className={ " itemMenu p-1 " + ( page.center === pages[0] ? "border-bottom" : "" )}  onClick={() => changePage(pages[0])}>{pages[0]}</div>
        <div className={ " itemMenu p-1 " + (page.center === pages[1] ? "border-bottom" : "" ) }  onClick={() => changePage(pages[1])}>{pages[1]}</div>
        <div className={ " itemMenu p-1 " + (page.center === pages[2] ? "border-bottom" : "" ) }  onClick={() => changePage(pages[2])}>{pages[2]}</div>

      </div>
    </nav>

    <div id="enTete" className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center text-light ">

    <Image src="img/programmeur-web.jpg" alt="programmeur-web"/>
      <div className="col-md-5 p-lg-5 mx-auto my-5">
        <h1 className="display-4 font-weight-normal">{page.center}</h1>
{/*         <p className="lead font-weight-normal">And an even wittier subheading to boot. Jumpstart your marketing efforts with this example based on Apple's marketing pages.</p>
        <a className="btn btn-outline-secondary" href="/portfolio">Coming soon</a> */}
      </div>
      <div className="product-device box-shadow d-none d-md-block"></div>
      <div className="product-device product-device-2 box-shadow d-none d-md-block"></div>
    </div>

{/*     <div className="col-12 row">
      <div className="col-6">
        <button className="btn btn-secondary"  onClick={() => changePage(page.left)}>{page.left}</button>
      </div>
      
      <div className="col-6 text-right">
        <button className="btn btn-secondary "  onClick={() => changePage(page.right)}>{page.right}</button>
      </div>
    </div> */}
      
      {page.center === "Compétences" ? <Competences /> :
         page.center === "Expériences" ? <Experiences /> :
         page.center === "Formations" && <Formations />
      }
      
      {/** About full screen */}
      {showAbout ? 
          <div id="about"  className="container-fluid bg-ligth">
            <div className="row">
        
            <div className="col-7 text-center m-auto">
                <div className="col-12 text-right mt-4">
                  <AiFillCloseCircle size={25} onClick={() => setShowAbout(false) }
                      cursor={"pointer"} 
                      onMouseOver={({target})=>target.style.color='rgba(255, 255, 260, 2)'} 
                      onMouseOut={({target})=>target.style.color='grey'}
                  />

                </div>
                    <div className="col-12  mb-2">
                      <Image className="picture" src="img/programmeur-web.jpg" alt="programmeur-web"/>
                    </div>

                    <div className="col-12 ">
                      <h1 >
                        A propos
                      </h1>
                    </div>

                    <div className="mt-3">
                      <p>
                        Passionné d'informatique depuis ma plus tendre enfance, j'ai toujours été attiré par le monde du numérique. <br/>
                        Je trouve que l'informatique est un formidable outil de création qui permet de trouver des solutions à de nombreuses problématiques.<br/>
                        <br/>Chaque jour est moyen d'apprendre et de développer de nouvelles compétences afin de progresser.
                      </p>
                      <a href="mailto:?to=loris.tchounang@gmail.com">
                        <HiMail size={25}/>
                      </a>
                      <a href="https://www.linkedin.com/in/loris-tchounang/">
                        <FaLinkedin size={25}/>
                      </a>
                    </div>

            </div>

          </div>
        </div>
       : ""}
    <footer className="container-fluid py-5 bg-light shad">
      <div className="row">
        <div className="col-12 col-md">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="d-block mb-2"><circle cx="12" cy="12" r="10"></circle><line x1="14.31" y1="8" x2="20.05" y2="17.94"></line><line x1="9.69" y1="8" x2="21.17" y2="8"></line><line x1="7.38" y1="12" x2="13.12" y2="2.06"></line><line x1="9.69" y1="16" x2="3.95" y2="6.06"></line><line x1="14.31" y1="16" x2="2.83" y2="16"></line><line x1="16.62" y1="12" x2="10.88" y2="21.94"></line></svg>
          <small className="d-block mb-3 text-muted">&copy; 2020-2021</small>
        </div>
        <div className="col-6 col-md">
          <h5>Pages</h5>
          <div className="linkFoot"  onClick={() => changePage("about")}>A propos</div>
          <div className="linkFoot"  onClick={() => changePage(pages[0])}>{pages[0]}</div>
          <div className="linkFoot"  onClick={() => changePage(pages[1])}>{pages[1]}</div>
          <div className="linkFoot"  onClick={() => changePage(pages[2])}>{pages[2]}</div>
        </div>

        <div className="col-6 col-md">
          <h5>Contact</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a className="text-muted"  href="mailto:?to=loris.tchounang@gmail.com">
                <HiMail size={25}/> Mail 
              </a>
            </li>
            <li>
              <a className="text-muted" href="https://www.linkedin.com/in/loris-tchounang/">
                <FaLinkedin size={25}/> Linkedin 
              </a>
            </li>
          </ul>
        </div> 
      </div>
    </footer>
    </>
  );
}

export default Portfolio;

