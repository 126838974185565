import React from 'react';

import { BrowserRouter , Switch, Route } from 'react-router-dom';

import Home from './screen/Home' ;
import Application from './screen/ApplicationScreen' ;
import Game from './screen/GameScreen' ;

import Portfolio from './screen/portfolio/Portfolio' ;
import NotFound from './screen/NotFound' ;

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={Home} />
          <Route path='/application' component={Application} />
          <Route path='/game' component={Game} />
          <Route path='/portfolio' component={Portfolio} />
          <Route path='/*' component={NotFound} />
      </Switch>
      </BrowserRouter>
  );
}

export default App;
