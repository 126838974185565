import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {

  return (

    <nav className="container-fluid navbar-comp text-light p-2">
      <div className="row">

        <div className="col-md-4 col-12 p-3 text-center">
          <Link className=" text-decoration-none text-light" to='/' style={{ textDecoration: 'none' }}>Coolespace.com</Link>

        </div>

        <div className="d-flex justify-content-center col-md-6 col-12 ">
          <div className="p-3 border-left">
            <Link className="p-3 text-decoration-none nav-menu-link" to='/application'>Applications</Link>
          </div>
          <div className="p-3 border-left">
            <Link className="p-3 text-decoration-none nav-menu-link" to='/game'>Jeux</Link>
          </div>

        </div>

      </div>
    </nav>
  );
}

export default Navbar;

